
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.Assess-wrapper {
  /*background-image: url("./images/leadership-background-optimized.jpg");
  background-image: url("https://media.pixcove.com/K/9/5/Grain-Ounce-Rye-Wheat-Free-Image-Rye-Field-Agricul-4894.jpg");
  background-image: url("https://www.artrenewal.org/secureimages/artwork/785/2785/15941/the_sun-lit_pines-large.jpg?&format=jpg&mode=max&w=577");*/

  position: absolute;
  top: 0;
  /*right: 0;
  bottom: 0;*/
  left: 0;
  transition: transitionX(0) ;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1);  /* gets rid of blurry edges of image */
  z-index: -2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  opacity: 1;
  /*background-color: #e6e6e6;*/
  margin: 0 auto;
  max-width: 600px;
  z-index: 1001;
  /*box-shadow: 0px -6px 45px rgba(0, 0, 0, 0.05)
  box-shadow: 0px -6px 35px rgba(255, 255, 255, 0.7)*/
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Assess-white-background {
  position: relative;
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Assess-description-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px 0px 30px;
  font-weight: 500;
  font-size: 110%;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Assess-data-block {
  display: flex;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Assess-heading {
  display: flex;
  flex-direction: row;
  font-size: 135%;
  margin: 12px 0 5px 0;
  padding: 0;
  font-weight: 600;
  height: 60px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.Assess-line {
  display: flex;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}


.Assess-heading-icon {
  margin-right: 5px;
  margin-left: -5px;
  height: 30px;
}
.Assess-button-wrapper {
  margin: 20px 30px;
  user-select: none;
}

.Assess-left-column {
  width: 100%;
}

.Assess-right-column {
  /*display: flex;
  align-items: flex-end;
  justify-content: flex-end;*/
  flex-grow: 1;
  text-align: right;
  width: 90px;
  margin-right: 25px;
  font-size: 14px;
  font-weight: normal;
  color: #808080;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.SelfAssessment-outer-bar {
  height: 35px;
  border: 1px solid #ddd;
  border-radius: 17px;
  width: 100%;
  /*max-width: 400px;*/
  margin: 15px auto 25px auto;
  padding: 5px;
  box-shadow: -5px -5px 5px rgba(255,255,255,0.4) inset, 5px 5px 5px rgba(0,0,0,0.1) inset;
  border: 1px solid rgba(0,0,0,0.05);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.Assessment-inner-bar {
  height: 100%;
  border-radius: 10px;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.SelfAssessment-bar-numbers {
  margin-top: -15px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-size: 110%;
  color: #a8a8a8;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* .CCButton {
  min-height: 45px;
  max-width: 340px;
  border-radius: 60px;
  width: 100%;
  padding: 10px 20px 10px 20px;
  margin-top: 10px;
  background-color: #f8c115;
  color: #666;
  font-size: 95%;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  outline: none;
}

.CCButton:a {
  background-color: #f9c908;
  border: none;
  outline: none;
}

.CCButton:hover, 
.CCButton:active,
.CCButton:visited {
  background-color: #f9c908;
  color: #333333;
  outline: none;
}

.CCButton:focus {
  outline: none;
  background-color: #f9c908;
} */

.Assess-menu {
  cursor: pointer;
  user-select: none;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 110%;
  color: #000000;
}

.Assess-status-menu {
  /* border: 2px solid lightblue; */
  font-weight: 600;
  font-size: 110%;
  list-style: none;
  text-align: center;
}

.Assess-status-menu li {
  cursor: pointer;
  font-weight: 600;
  /* font-size: 110%; */
  color: #555;
  /* border: 1px solid greenyellow; */
  margin: 18px 0;
  padding: 0;
}