
.spinner {
  border: 5px solid rgba(214, 214, 214, 0.7);
  border-radius: 50%;
  border-top: 5px solid #bbbbbb;
  border-right: 5px solid #bbbbbb;
  border-bottom: 5px solid #bbbbbb;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 0.57s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



react-autosuggest__container {
  position: absolute;
  border: 2px solid red;
}

.react-autosuggest__input {
  width: 100%;
  max-width: 350px;
  height: 30px;
  padding: 18px 40px 18px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.react-autosuggest__input--focused {
  outline: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  top: 100px;
  max-width: 350px;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
