
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.LCButton {
  border: none;
  color: #777777;
  font-size: 115%;
  letter-spacing: 0.075rem;
  box-shadow: 4px 3px 6px rgba(0,0,0,0.2), -4px -2px 5px rgba(255,255,255,0.3), 0 0 6px #f1f1f1 ;
  background-image: linear-gradient(to bottom right, #dbdbdb, #ebebeb);
  z-index: 0; 
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

.LCButton:a {
  background-color: #e6e6e6;
  border: none;
  outline: none;
}

.LCButton:hover, 
.LCButton:active,
.LCButton:visited {
  color: #000000;
  outline: none;
}

.LCButton:focus {
  outline: none;
}

/*  */

.LCTab {
  border: none;
  color: #777777;
  font-size: 115%;
  letter-spacing: 0.075rem;
  box-shadow: 0 -5px 5px rgba(255,255,255,0.5), 0 0 6px #f1f1f1 ;
  background-image: linear-gradient(to bottom right, #dbdbdb, #ebebeb);
  z-index: 0; 
  cursor: pointer;
}

.LCTab:a {
  background-color: #e6e6e6;
  border: none;
  outline: none;
}

.LCTab:hover, 
.LCTab:active,
.LCTab:visited {
  color: #000000;
  outline: none;
}

.LCButton:focus {
  outline: none;
}

/* .CCButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0;
  background-color: #e6e6e6;
  box-shadow: 0 0 25px rgba(0,0,0,0.15);
  user-select: none;
} */

.CCButtonAlt {
  border: '2px solid red';
  box-shadow: 0px 0px 4px rgba(0,0,0,0.30), -4px -2px 5px rgba(255,255,255,0.7), 0 0 6px #f1f1f1 ;
  width: 100%;
  height: 50px;
  border-radius: 20px;
  background-color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: right;
  /* margin: auto 0; */
  padding: 0;
  /* border: 1px dashed purple; */
  user-select: none;
}