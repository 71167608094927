.DoAssessment-definition{
  color: #333;

}

.DoAssessment-definition .name{

}

.DoAssessment-definition .description{
  font-size: 80%;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}