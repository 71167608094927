
.App-forms input {
  font-size: 115%;
  padding: 10px 18px 12px 18px;
  color: #333;
  outline: none;
  border-radius: 13px;
  margin-bottom: 35px;
}

.App-forms button {
  border-radius: 13px;
}

::placeholder {
  color: #999;
  font-size: 1.1em;
}