
.form-control {
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.form-label {
  color: #777;
  font-size: 90%;
  margin-bottom: -8px;
}